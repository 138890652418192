@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Zen+Antique&display=swap");

* {
  font-family: "Roboto", sans-serif;
}
.summary {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  align-items: center;

  .order {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;
      margin-top: 40px;

      #main {
        font-size: 25px;
        font-weight: bold;
        color: #424242;
      }

      #add {
        color: #e92f48;
        font-size: 17px;
        font-weight: bold;
      }
    }

    .dishes {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e8e9ea;

        .left {
          display: flex;
          align-items: center;
          width: 60%;

          .index {
            background-color: #e92f48;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            border-radius: 5px;
            margin-top: -13px;
            margin-right: 10px;
          }

          .name {
            display: flex;
            flex-direction: column;

            .one {
              font-size: 18px;
              font-style: italic;
              color: #424242;
            }

            .two {
              font-weight: 200;
              font-size: 15px;
              color: #7e8389;
            }
          }
        }

        .right {
          span {
            color: #494949;
          }
        }
      }
    }
  }

  .sum {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 50px;

    .title {
      span {
        color: #424242;
        border-bottom: 3px solid #e92f48;
        font-size: 25px;
        font-weight: bold;
      }
      margin-bottom: 30px;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0px;
      border-bottom: 1px solid #e8e9ea;

      & :first-child {
        color: #7e8389;
      }

      :nth-child(2) {
        color: #424242;
      }
    }

    #total {
      border: none;
      margin-top: 15px;

      & :first-child {
        font-size: 25px;
        font-weight: bold;
        color: #424242;
      }

      & :nth-child(2) {
        color: #424242;
        font-size: 20px;
        font-weight: bold;
      }
    }

    #discount {
      & :nth-child(2) {
        color: #5a8cd7;
      }
    }
  }

  .order-btn {
    width: 70%;
    margin-top: 30px;
    background-color: black;
    border: none;
    color: white;
    font-size: 20px;
    padding: 7px 0px;
    border-radius: 10px;
    cursor: pointer;
  }
}
