.topbar {
  position: static;
  -webkit-box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.45);
  width: 70%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 80px;
  padding: 25px 0px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  &::before {
    content: "";
    width: 100%;
    height: 200px;
    z-index: -20;
    top: 0px;
    position: absolute;
    background-color: yellow;
    background: url("https://i.pinimg.com/originals/02/99/02/0299026ae1a0156e20f94b05c6079312.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .title {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 20px;
  }

  .buttons {
    margin-bottom: 50px;
    #delivery {
      background-color: black;
      color: whitesmoke;
      border: none;
      border-radius: 10px;
      padding: 3px 70px;
    }

    #pickup {
      border-radius: 10px;
      border: 1px solid black;
      padding: 3px 70px;
    }
  }

  .mid {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
    margin-bottom: 30px;
  }

  .bottom {
    font-weight: bold;
  }
}
